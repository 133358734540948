import { Price as DesignPrice } from 'mycs/api/DesignAPI';
import { mandatory } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import cfg from 'mycs/config';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';
import MetaConversionsAPIService, { EventNames } from 'mycs/shared/services/AnalyticsService/MetaConversionsAPIService';

class SaveDesignEmailService {
  postSaveDesign = false;

  init() {
    this.postSaveDesign = false; // Used by track event in StructureAnalyticsService
  }

  /**
   * Send save design email. Analytics event triggers CrossEngage logic
   */
  async sendSaveDesignEmail(
    design: any = mandatory('sendSaveDesignEmail: design is undefined'),
    price: DesignPrice = mandatory('sendSaveDesignEmail: price is undefined'),
    locale: string,
    countryCode: string,
    pathname: string,
    pageData: any
  ) {
    MetaConversionsAPIService.eventTrack(EventNames.AddToWishlist, {
      value: price?.totalPriceInclTaxes,
      content_ids: [design?.uuid || ''],
      content_category: design?.furniture_type,
      content_name: design?.name
    });

    const pageType =
      LocationUtils.getPageType(locale, pathname, pageData) || '';

    const eventWeight =
      cfg.eventWeight[pageType]?.['saveDesignSent']?.[countryCode];

    const eventName = 'saveDesignSent';
    const { colors } = design;
    const imageSecondary =
      (design.furniture_type === 'wardrobe' &&
        this.getDesignSecondaryImageURL(design)) ||
      '';

    // TODO do not wrap dimensions in array
    // https://github.com/mycsHQ/mycs-js/blob/master/apps/website/app/src/shared/services/DesignApiService/DesignApiService.ts#L189-L191
    const dimensions = Array.isArray(design.dimensions)
      ? design.dimensions[0]
      : design.dimensions;

    const tag = {
      event: eventName,
      email_sha256: '', // tmp added until BI removes it from their ETLs
      pageURL: WindowLocationUtils.getUrlPathAndSearch(),
      ...(eventWeight && {
        weight: price.totalPriceInclTaxes * eventWeight,
      }),
      design: {
        image: this.getDesignMainImageURL(design),
        imageSecondary,
        url: UrlProviderService.getFurnitureUrl(
          locale,
          design.furniture_type,
          design.uuid
        ),
        title: I18nUtils.localize(locale, design.name),
        price: price.totalPriceInclTaxes,
        color1: I18nUtils.localize(locale, colors.primary || colors.cover),
        color2: I18nUtils.localize(
          locale,
          colors.secondary || colors.primary || colors.cover
        ),
        height: Math.ceil(dimensions.height / 10),
        length: Math.ceil(dimensions.length / 10),
        width: Math.ceil(dimensions.width / 10),
        currency: price.currencyCode,
        uuid: design.uuid,
        category: design.furniture_type,
      },
    };

    this.postSaveDesign = true;

    AnalyticsService.eventTrack(eventName, locale, tag, false, EventNames.AddToWishlist);
  }

  /**
   * Get design main image URL.
   */
  getDesignMainImageURL(design: any) {
    const imageMain = design.images.find((image: any) => image.type === 'MAIN');
    if (imageMain) {
      return UrlProviderService.getAbsoluteAssetUrl(imageMain.url);
    }

    if (design.image_url) {
      return UrlProviderService.getAbsoluteAssetUrl(design.image_url);
    }

    return '';
  }

  /**
   * Get design secondary image URL, if the image is present.
   *
   * @param {*} design
   * @returns {string}
   */
  getDesignSecondaryImageURL(design: any): string {
    const imageMainWithoutDoors = design.images.find(
      (image: any) => image.type === 'MAIN_WITHOUT_DOORS'
    );
    if (imageMainWithoutDoors) {
      return UrlProviderService.getAbsoluteAssetUrl(imageMainWithoutDoors.url);
    }

    return '';
  }
}

export default new SaveDesignEmailService();
