import { User } from 'mycs/api/UserAPI';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import Logger from 'mycs/shared/services/Logger';
import UrlProviderService from '../UrlProviderService/UrlProviderService';
import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';
import uuidV4 from 'uuid/v4';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import hashSHA256 from 'mycs/shared/utilities/CryptoUtils/CryptoUtils';
import ReportingAPIService from 'mycs/shared/services/AnalyticsService/ReportingAPIService';
import { CheckoutUser } from 'mycs/services/CheckoutUserService';

export interface MetaAPIEvent {
    event_name: string,
    event_time: number;
    user_data: UserData;
    event_id?: string;
    action_source: string;
    event_source_url: string;
    custom_data?: CustomData;
}

interface UserData {
    em?: string[];
    fn?: string[];
    ln?: string[];
    ph?: string[];
    ct?: string[];
    zp?: string[];
    external_id: string;
    country: string[];
    fbp?: string;
}

enum ContentType {
    PRODUCT = "product",
    PRODUCT_GROUP = "product_group"
}

interface CustomData {
    value?: number;
    currency?: string;
    content_ids?: string[];
    content_type?: ContentType;
    content_category?: string;
    content_name?: string;
    num_items?: number;
}

export enum EventNames {
    PageView = "PageView",
    ViewContent = "ViewContent",
    ViewConfigurator = "ViewConfigurator",
    AddToCart = "AddToCart",
    AddToWishlist = "AddToWishlist",
    Lead = "Lead",
    Purchase = "Purchase",
    InitiateCheckout = "InitiateCheckout",
    CompleteRegistration = "CompleteRegistration",
    AddPaymentInfo = "AddPaymentInfo",
    Samples = "Samples",
    CustomizeProduct = "CustomizeProduct",
    config_3min = "config_3min"
}

export class MetaConversionsAPIService {
    userData?: User;
    checkoutUserData?: CheckoutUser;
    orderData?: CustomData;
    locale?: string;
    country?: string;
    static readonly websiteActionSource = "website"

    eventTrack(
        name: string,
        custom_data?: CustomData
    ) {
        this._decorateEventData(name, custom_data).then((data) => {
            this.postMetaAPI(data).catch(
                (error: Error) =>
                    Logger.error('An error occurred in meta api pipeline:', error),
            );
        });
    }

    checkoutEventTrack(
        name: string
    ) {
        this._decorateEventData(name, this.orderData).then((data) => {
            this.postMetaAPI(data).catch(
                (error: Error) =>
                    Logger.error('An error occurred in meta api pipeline:', error),
            );
        });
    }

    async _decorateEventData(event_name: string, custom_data?: CustomData): Promise<MetaAPIEvent> {
        let eventData: MetaAPIEvent = {
            event_name,
            event_id: this.getEventId(event_name),
            event_time: Math.floor(Date.now() / 1000),
            user_data: await this._decorateUserData(),
            action_source: MetaConversionsAPIService.websiteActionSource,
            event_source_url: window.location.href
        }

        if (custom_data) {
            eventData.custom_data = {
                ...custom_data,
                content_type: ContentType.PRODUCT,
                currency: I18nUtils.getCurrencyFormat(this.locale || '').currency
            }
        }

        return eventData;
    }

    async _decorateUserData(): Promise<UserData> {
        const visitorId =
            LocalStorage.get(ReportingAPIService.visitorIdStorageKey) || uuidV4();
        LocalStorage.set(ReportingAPIService.visitorIdStorageKey, visitorId);
        const external_id = this.userData?.id ?? visitorId;
        const em = await this._collectStringHashes([this.userData?.email, this.checkoutUserData?.email]);
        const countries = await this._collectStringHashes([this.country, this.checkoutUserData?.billingAddress?.country, this.checkoutUserData?.shippingAddress.country]);
        const ct = await this._collectStringHashes([this.checkoutUserData?.billingAddress?.city, this.checkoutUserData?.shippingAddress.city]);
        const fn = await this._collectStringHashes([this.checkoutUserData?.billingAddress?.firstName, this.checkoutUserData?.shippingAddress?.firstName]);
        const ln = await this._collectStringHashes([this.checkoutUserData?.billingAddress?.lastName, this.checkoutUserData?.shippingAddress?.lastName]);
        const ph = await this._collectStringHashes([this.checkoutUserData?.billingAddress?.phone, this.checkoutUserData?.shippingAddress?.phone]);
        const zp = await this._collectStringHashes([this.checkoutUserData?.billingAddress?.zip, this.checkoutUserData?.shippingAddress?.zip]);
        const fbp = this._getFbpParameter();

        return {
            em,
            country: countries,
            external_id,
            ct,
            fn,
            ln,
            ph,
            zp,
            fbp
        }
    }

    async _collectStringHashes(values: (string | undefined)[]): Promise<string[]> {
        const result: string[] = [];

        const addHashIfValid = async (value?: string): Promise<void> => {
            if (value) {
                const hashedValue = await hashSHA256(value.toLowerCase().trim());
                result.push(hashedValue);
            }
        };

        for (const value of values) {
            await addHashIfValid(value);
        }

        return result;
    }

    setUserData(userData?: User) {
        this.userData = userData;
    }

    setCheckoutUserData(checkoutUser?: CheckoutUser) {
        this.checkoutUserData = checkoutUser;
    }

    // The order list does not change after entering checkout, but cart and order objects are not available through
    // the whole flow => keeping the order object here
    setOrderData(orderData: CustomData) {
        this.orderData = orderData;
    }

    getOrderData(): CustomData {
        return this.orderData || {};
    }

    setCountry(country: string) {
        this.country = country;
    }

    setLocale(locale: string) {
        this.locale = locale;
    }

    postMetaAPI(data: MetaAPIEvent): Promise<Response> {
        const metaApiUrl = UrlProviderService.getMetaConvertionsApiUrl();
        return FetchAPI.post(metaApiUrl, { data: [data], test_event_code: 'TEST93602' });
    }

    getEventId(event_name: string): string {
        let eventId = sessionStorage.getItem(`${event_name}_id`);

        if (!eventId) {
            eventId = uuidV4();
            sessionStorage.setItem(`${event_name}_id`, eventId!);
        } else {
            sessionStorage.removeItem(`${event_name}_id`);
        }

        return eventId!;
    }

    _getFbpParameter(): string | undefined {
        return document.cookie
            .split('; ')
            .find(row => row.startsWith('_fbp='))
            ?.split('=')[1] || undefined;
    }
}

export default new MetaConversionsAPIService();