import joynConfig, { SofaFurnitureConfig } from './joyn-config';
import flatten from 'lodash/flatten';
// import invert from 'lodash/invert';
import { Option } from 'src/types';
import { screenFactor } from '../utils/configUtils';
import nyceConfig from './nyce-config';
import Utils from '../utils/utils';

const baseCameraSetup = {
  i1: { angle: -30, vAngle: -15 },
  i2: { angle: -30, vAngle: -15 },
  i3: { angle: -30, vAngle: -15 },
  i4: { angle: -30, vAngle: -15 },
  L1: { angle: -45, vAngle: -35 },
  L2: { angle: 0, vAngle: -45 },
  L3: { angle: 0, vAngle: -45 },
  L4: { angle: 0, vAngle: -45 },
  U1: { angle: 0, vAngle: -45 },
  U2: { angle: 0, vAngle: -45 },
  U3: { angle: 0, vAngle: -45 },
  U4: { angle: 0, vAngle: -45 },
};

const reviewCameraSetup = Utils.cloneDeep(baseCameraSetup);
Object.keys(reviewCameraSetup).forEach(
  (pattern) => (reviewCameraSetup[pattern].vAngle = -15)
);

const backCushionsCameraSetup = Utils.cloneDeep(baseCameraSetup);
Object.keys(backCushionsCameraSetup).forEach((pattern) => {
  backCushionsCameraSetup[pattern].angle = -120;
  backCushionsCameraSetup[pattern].vAngle = -20;
});

const covers = {
  'Diamante Covers': [
    'diamante_white_12106',
    'diamante_beige_2530',
    'diamante_brown_4099',
    'diamante_brown_3995',
    'diamante_grey_2608',
    'diamante_grey_12108',
    'diamante_anthracite_4045',
    'diamante_beige_4104',
    'diamante_beige_12107',
    'diamante_blue_12104',
    'diamante_blue_2610',
    'diamante_blue_2535',
    'diamante_green_4909',
  ],
  'Brunei Covers': [
    'brunei_black_1',
    'brunei_grey_2',
    'brunei_grey_5',
    'brunei_beige_4',
    'brunei_beige_9',
    'brunei_brown_14',
    'brunei_azur_38',
    'brunei_azur_39',
    'brunei_azur_40',
    'brunei_blue_33',
    'brunei_pink_29',
    'brunei_pink_30',
    'brunei_pink_31',
    'brunei_salmon_20',
    'brunei_magenta_26',
    'brunei_purpule_24',
    'brunei_violet_28',
    'brunei_green_44',
    'brunei_green_45',
    'brunei_yellow_41',
  ],
  'Velvetex Covers': [
    'velvetex_anthracite_1002',
    'velvetex_grey_1000',
    'velvetex_grey_1042',
    'velvetex_grey_9115',
    'velvetex_green_104612',
    'velvetex_grey_101012',
    'velvetex_grey_101013',
    'velvetex_brown_2134',
    'velvetex_brown_9202',
    'velvetex_beige_2256',
    'velvetex_beige_2237',
    'velvetex_yellow_2272',
    'velvetex_violet_2255',
    'velvetex_pink_2315',
    'velvetex_pink_103637',
    'velvetex_red_2306',
    'velvetex_orange_103230',
    'velvetex_orange_104224',
    'velvetex_blue_2277',
    'velvetex_violet_104250',
    'velvetex_blue_1007',
    'velvetex_blue_104041',
    'velvetex_green_2253',
    'velvetex_green_9139',
    'velvetex_blue_2019',
    'velvetex_green_1375',
    'velvetex_green_210',
    'velvetex_orange_1090',
    'velvetex_blue_1203',
    'velvetex_blue_1372',
    'velvetex_violet_101',
    'velvetex_blue_1524',
    'velvetex_violet_1573',
  ],
  'Lech Covers': [
    'lech_lawa_6',
    'lech_lawa_7',
    'lech_lawa_11',
    'lech_sumatra_6',
    'lech_kreta_2',
    'lech_kreta_7',
    'lech_kreta_9',
    'lech_peru_1',
    'lech_peru_6',
    'lech_erie_1',
    'lech_erie_6',
    'lech_erie_8',
    'lech_erie_12',
    'lech_erie_15',
    'lech_erie_16',
  ],
  'Fine Fabric Covers': [
    'bahama_3',
    'bahama_8',
    'bahama_9',
    'bahama_30',
    'bahama_34',
    'oxford_9',
    'fargotex_river_1',
    'fargotex_river_5',
    'fargotex_river_6',
    'fargotex_river_10',
    'fargotex_river_11',
    'fargotex_river_12',
    'fargotex_river_13',
    'fargotex_river_14',
  ],
  belize: [
    'belize_23',
    'belize_25',
    'belize_27',
    'belize_32',
    'belize_64',
    'belize_74',
    'belize_561',
  ],
  zeppel: [
    'zepel_fenno_1',
    'zepel_fenno_3',
    'zepel_fenno_5',
    'zepel_fenno_6',
    'zepel_fenno_7',
    'zepel_fenno_8',
    'zepel_fenno_9',
    'zepel_fenno_12',
    'zepel_fenno_13',
    'zepel_fenno_15',
  ],
  ribcord: [
    'ribcord_anthracite_67',
    'ribcord_chocolate_17',
    'ribcord_grey_180',
    'ribcord_onyx_169',
    'ribcord_petrol_56',
    'ribcord_taupe_12',
  ],
  scala: [
    'scala_anthracite_2',
    'scala_ash_8',
    'scala_chocolate_4',
    'scala_forest_13',
    'scala_lighter_green_12',
    'scala_mustard_16',
    'scala_navy_10',
    'scala_toffee_6',
    'scala_truffle_22',
    'scala_yellow_15',
  ],
  galaxy: [
    'galaxy_anthra_0074',
    'galaxy_dark_blue_0041',
    'galaxy_light_beige_0025',
    'galaxy_light_grey_0101',
    'galaxy_taupe_0056',
    'galaxy_olive_green_0062',
    'galaxy_jean_blue_0053',
    'galaxy_bramble_red_0063',
  ],
};

const validHeights = [390, 450]; // Base (180, 240) + Legs (40) + Seating cushion (180)
const validDepths = [780, 1020, 1260];

const validDepthsLabelMapping = {
  780: 'Up-right',
  1020: 'Standard',
  1260: 'Laid-back',
};

// from moduleHeight
const armrestHeightMapping = {
  390: 560,
  450: 620,
};

// from moduleHeight
const backrestHeightMapping = {
  390: 560,
  450: 620,
};

// from moduleHeight
const baseHeightMapping = {
  390: 180,
  450: 240,
};

const moduleHeightMapping = {
  180: 390,
  240: 450,
};

const config: SofaFurnitureConfig = {
  // Custom properties
  armrestHeightMapping,
  backrestHeightMapping,
  baseHeightMapping,
  moduleHeightMapping,
  backCushionHeight: 440,
  backRollCushionHeight: 530,
  seatingCushionHeight: 180,
  defaultColor: 'diamante_grey_12108',
  validHeights,
  validDepths,
  defaultExtensionDepth: 540,
  // Default properties
  hasScene: false,
  scenesOptions: [],
  hasAssembly: true,
  hasViewInterior: false,
  showRelatedProduct: false,
  noBackgroundCarousel: false,
  translationDimensionMapping: true,
  defaultArmrestColor: 'backrest',
  defaultArmrestLength: 240,
  defaultBackrestDepth: 240,
  laminateMapping: {},
  lacquerMapping: {},
  colors: {},
  default: {
    modules: [
      {
        armrestLength: [0, 0],
        extensionsLength: [0, 0],
        color: {},
        colors: {
          armrest: ['diamante_grey_12108', 'diamante_grey_12108'],
          backrest: ['diamante_grey_12108', 'diamante_grey_12108'],
          backCushion: ['diamante_grey_12108', 'diamante_grey_12108'],
          base: ['diamante_grey_12108', 'diamante_grey_12108'],
          cushion: ['diamante_grey_12108', 'diamante_grey_12108'],
        },
        direction: 'none',
        moduleLength: 780,
        sleepingMode: false,
        ottomanMode: false,
        storageMode: false,
        type: 'base', // base, corner
        // Flayr only
        moduleDepth: 780,
        moduleHeight: 450, // Legs + Base + Seating cushion + Armrest/Backrest height
        backCushionHeight: 440,
        backCushionLength: 780,
        extensionsSquare: [false, false],
      },
    ],
  },
  nyce: {
    animation: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1200, 700, 133, 7.5, 203, 203),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    normal: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 166.25, 9.38, 253.75, 253.75),
      },
      processing: {
        noise: 0.1,
        samples: 250,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    mobile: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 50, 50),
      },
      processing: {
        noise: 0.1,
        samples: 250,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
    hires: {
      camera: {
        fov: 40,
        angle: -30,
        vAngle: -15,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.sofa,
      },
    },
  },
  backUrl: '/couches-and-armchairs',
  maxSofaLength: 10000,
  label: 'SVG',
  moduleWithOneCushion: [540, 780, 1020],
  endWithOneCushion: [],
  furnitureType: 'flayr',
  invalidUuidFallback: 'gOcixGgBv',
  stage: 'default',
  tabs: {
    desktop: ['default'],
    mobile: ['modulesMobile', 'elementsMobile', 'colorsMobile', 'review'],
  },
  tabsNextText: [
    'To dimensions',
    'To modules',
    'To back cushions',
    'To colors',
    'To review',
  ],
  // The folowing filter are to prevent technical specs from displaying on the website
  dimensionsFilters: joynConfig.dimensionsFilters,
  assemblyFeature: 7,
  productDetailText: 'About Our Flayr Sofa',
  forbiddenExtensionModuleLength: [],
  options: [
    // Dimension options
    ...validHeights.map((value) => {
      const heightInCm = value / 10;
      return {
        action: 'update',
        group: 'dimension',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'height',
        text: `${heightInCm} cm`,
        value,
        iconName: `configurator/flayr/flayr_height_${heightInCm}cm`,
      };
    }),
    ...validDepths.map((value) => {
      const depthInCm = value / 10;
      return {
        action: 'update',
        group: 'dimension',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'depth',
        text: `${depthInCm} cm<br>${validDepthsLabelMapping[value]}`,
        value,
        iconName: `configurator/flayr/flayr_depth_${depthInCm}cm`,
      };
    }),
    // Module options
    // Armrests
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '12 cm',
      length: 120,
      iconName: 'configurator/flayr/flayr_armrest_12cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'left',
      text: '24 cm',
      length: 240,
      iconName: 'configurator/flayr/flayr_armrest_24cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '12 cm',
      length: 120,
      iconName: 'configurator/flayr/flayr_armrest_12cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'armrest',
      direction: 'right',
      text: '24 cm',
      length: 240,
      iconName: 'configurator/flayr/flayr_armrest_24cm',
    },
    // Bases
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '54 x 78 cm',
      length: 540,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backseat_54x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '54 x 102 cm',
      length: 540,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backseat_54x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '54 x 126 cm',
      length: 540,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backseat_54x126cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '78 x 78 cm',
      length: 780,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backseat_78x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '78 x 102 cm',
      length: 780,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backseat_78x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '78 x 126 cm',
      length: 780,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backseat_78x126cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '102 x 78 cm',
      length: 1020,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backseat_102x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '102 x 102 cm',
      length: 1020,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backseat_102x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      direction: 'none',
      text: '102 x 126 cm',
      length: 1020,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backseat_102x126cm',
    },
    // Bases ottoman
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 54 cm',
      length: 540,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_54x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 66 cm',
      length: 540,
      depth: 660,
      iconName: 'configurator/flayr/flayr_backless_seat_54x66cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 78 cm',
      length: 540,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_seat_54x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 90 cm',
      length: 540,
      depth: 900,
      iconName: 'configurator/flayr/flayr_backless_seat_54x90cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 102 cm',
      length: 540,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_seat_54x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 114 cm',
      length: 540,
      depth: 1140,
      iconName: 'configurator/flayr/flayr_backless_seat_54x114cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 54 x 126 cm',
      length: 540,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_seat_54x126cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 78 x 126 cm',
      length: 780,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_seat_78x126cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 66 x 66 cm',
      length: 660,
      depth: 660,
      iconName: 'configurator/flayr/flayr_backless_seat_66x66cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 78 x 54 cm',
      length: 780,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_78x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 78 x 78 cm',
      length: 780,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_seat_78x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 78 x 102 cm',
      length: 780,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_seat_78x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 90 x 54 cm',
      length: 900,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_90x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 90 x 90 cm',
      length: 900,
      depth: 900,
      iconName: 'configurator/flayr/flayr_backless_seat_90x90cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 102 x 54 cm',
      length: 1020,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_102x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 102 x 78 cm',
      length: 1020,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_seat_102x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 102 x 102 cm',
      length: 1020,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_seat_102x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 114 x 54 cm',
      length: 1140,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_114x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 114 x 114 cm',
      length: 1140,
      depth: 1140,
      iconName: 'configurator/flayr/flayr_backless_seat_114x114cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 126 x 54 cm',
      length: 1260,
      depth: 540,
      iconName: 'configurator/flayr/flayr_backless_seat_126x54cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: 'Ottomans 126 x 126 cm',
      length: 1260,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_seat_126x126cm',
    },
    // Bases ottoman storage
    // 2nd release (UX limitation for now)
    // {
    //   action: 'update',
    //   group: 'module',
    //   isDisabled: null,
    //   isSelected: null,
    //   message: '',
    //   value: '',
    //   type: 'storage_base',
    //   direction: 'none',
    //   text: '54 x 54 cm',
    //   length: 540,
    //   depth: 540,
    //   iconName: 'configurator/flayr/flayr_backless_storage_54x54cm'
    // },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 66 x 66 cm',
      length: 660,
      depth: 660,
      iconName: 'configurator/flayr/flayr_backless_storage_66x66cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 78 x 78 cm',
      length: 780,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_storage_78x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 90 x 90 cm',
      length: 900,
      depth: 900,
      iconName: 'configurator/flayr/flayr_backless_storage_90x90cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 102 x 102 cm',
      length: 1020,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_storage_102x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 114 x 114 cm',
      length: 1140,
      depth: 1140,
      iconName: 'configurator/flayr/flayr_backless_storage_114x114cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Storage 126 x 126 cm',
      length: 1260,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_storage_126x126cm',
    },
    // Sleeping module
    // {
    //   action: 'update',
    //   group: 'module',
    //   isDisabled: null,
    //   isSelected: null,
    //   message: '',
    //   value: '',
    //   type: 'base',
    //   direction: 'none',
    //   sleepingMode: true,
    //   text: 'Sleeping module',
    //   length: 2000,
    //   iconName: 'configurator/flayr/flayr_sleeping_module',
    // },
    // Corner module
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      direction: 'none',
      text: 'Corners 78 x 78 cm',
      length: 540,
      depth: 780,
      iconName: 'configurator/flayr/flayr_corner',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      direction: 'none',
      text: 'Corners 102 x 102 cm',
      length: 780,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_corner',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      direction: 'none',
      text: 'Corners 126 x 126 cm',
      length: 1020,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_corner',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      direction: 'none',
      text: 'Corners Ottomans 78 x 78 cm',
      length: 780,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_seat_78x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      direction: 'none',
      text: 'Corners Ottomans 102 x 102 cm',
      length: 1020,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_seat_102x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      direction: 'none',
      text: 'Corners Ottomans 126 x 126 cm',
      length: 1260,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_seat_126x126cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Corners Storage 78 x 78 cm',
      length: 780,
      depth: 780,
      iconName: 'configurator/flayr/flayr_backless_storage_78x78cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Corners Storage 102 x 102 cm',
      length: 1020,
      depth: 1020,
      iconName: 'configurator/flayr/flayr_backless_storage_102x102cm',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'corner',
      ottomanMode: true,
      storageMode: true,
      direction: 'none',
      text: 'Corners Storage 126 x 126 cm',
      length: 1260,
      depth: 1260,
      iconName: 'configurator/flayr/flayr_backless_storage_126x126cm',
    },
    // Extensions
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 66 x 54 cm',
      length: 120,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 90 x 54 cm',
      length: 120,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 114 x 54 cm',
      length: 120,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 78 x 54 cm',
      length: 240,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 102 x 54 cm',
      length: 240,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: false,
      text: 'Lounge Left 126 x 54 cm',
      length: 240,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_left_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 66 x 54 cm',
      length: 120,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 90 x 54 cm',
      length: 120,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 114 x 54 cm',
      length: 120,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 78 x 54 cm',
      length: 240,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 102 x 54 cm',
      length: 240,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: false,
      text: 'Lounge Right 126 x 54 cm',
      length: 240,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_right_54',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 66 x 66 cm',
      length: 120,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 90 x 90 cm',
      length: 120,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 114 x 114 cm',
      length: 120,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 78 x 78 cm',
      length: 240,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 102 x 102 cm',
      length: 240,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'left',
      square: true,
      text: 'Lounge Left 126 x 126 cm',
      length: 240,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_left_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 66 x 66 cm',
      length: 120,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 90 x 90 cm',
      length: 120,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 114 x 114 cm',
      length: 120,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 78 x 78 cm',
      length: 240,
      moduleLength: 540,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 102 x 102 cm',
      length: 240,
      moduleLength: 780,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'extension',
      direction: 'right',
      square: true,
      text: 'Lounge Right 126 x 126 cm',
      length: 240,
      moduleLength: 1020,
      iconName: 'configurator/flayr/flayr_lounge_right_square',
    },
    // Delete module
    {
      action: 'remove',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'any',
      direction: 'none',
      text: 'Remove',
      length: 0,
      iconName: 'configurator/flayr/delete',
    },
    // Back cushion options
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_cushion',
      direction: 'none',
      text: '54 cm',
      length: 540,
      height: 440,
      iconName: 'configurator/flayr/flayr_cushion_standard_54cm',
    },
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_cushion',
      direction: 'none',
      text: '78 cm',
      length: 780,
      height: 440,
      iconName: 'configurator/flayr/flayr_cushion_standard_78cm',
    },
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_cushion',
      direction: 'none',
      text: '102 cm',
      length: 1020,
      height: 440,
      iconName: 'configurator/flayr/flayr_cushion_standard_102cm',
    },
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_roll_cushion',
      direction: 'none',
      text: '54 cm',
      length: 540,
      height: 530,
      iconName: 'configurator/flayr/flayr_cushion_roll_54cm',
    },
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_roll_cushion',
      direction: 'none',
      text: '78 cm',
      length: 780,
      height: 530,
      iconName: 'configurator/flayr/flayr_cushion_roll_78cm',
    },
    {
      action: 'update',
      group: 'back_cushion',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'back_roll_cushion',
      direction: 'none',
      text: '102 cm',
      length: 1020,
      height: 530,
      iconName: 'configurator/flayr/flayr_cushion_roll_102cm',
    },
    // Cover color options
    ...flatten(
      Object.keys(covers).map(
        (category: string): Option =>
          covers[category].map(
            (color: string): Option => ({
              action: 'update',
              group: 'color',
              isDisabled: null,
              isSelected: null,
              message: '',
              type: 'cover',
              value: color,
              iconName: '',
              text: '',
            })
          )
      )
    ),
  ],
  dimensions: {
    armrestWidths: [120, 240],
    depth: 780, // normal base width
    depthWithExtension: 780, // normal base + extension base width
  },
  defaultDimensions: {
    width: 0,
    length: 0,
    height: 0,

    center_length: 0,
    center_depth: 0,
    center_depth_withExt: 0,
    center_height: 0,

    left_length: 0,
    left_depth: 0,
    left_depth_withExt: 0,
    left_height: 0,

    right_length: 0,
    right_depth: 0,
    right_depth_withExt: 0,
    right_height: 0,
    seat_depth: 780,
    seat_depth_extension: 0,
    seat_height: 600,
    high_seat_height: 660,

    seat_width: 780,
    seat_width_extension: 0,
    regular_depth: 780,
    pouf_depth: 0,
    extension_depth: 0,
  },
  viewModes: {
    dimensions: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'dimension',
      camera: baseCameraSetup,
      labelMode: '',
    },
    height: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'dimension',
      camera: baseCameraSetup,
      labelMode: '',
    },
    depth: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      groupStructure: 'dimension',
      camera: baseCameraSetup,
      labelMode: '',
    },
    'seat-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    lounge: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    'corner-modules': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    'open-modules-storage-space': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    armrests: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    modules: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      camera: baseCameraSetup,
      labelMode: 'module',
    },
    elements: {
      zoomMax: 1.8,
      subTabsName: ['height', 'depth', 'modules', 'back-cushions'],
      defaultSubTabName: 'height',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: reviewCameraSetup,
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: baseCameraSetup,
      group: 'color',
      groupStructure: '',
      iconName: '',
      labelMode: 'color_whole',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: [
        'height',
        'depth',
        'modules',
        'back-cushions',
        'colorsMobile',
      ],
      text: '',
    },
    'back-cushions': {
      zoomMax: 1,
      subTabsName: [
        'Cushion 60 cm height without roll',
        'Cushion 85 cm height with roll',
      ],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: false,
      group: 'back_cushion',
      camera: backCushionsCameraSetup,
      labelMode: 'back_cushion',
      defaultSubTabName: 'Cushion 60 cm height without roll',
    },
    'Cushion 60 cm height without roll': {
      zoomMax: 1,
      subTabsName: [],
      text: 'Cushion 60 cm height without roll',
      iconName: 'configurator/flayr/flayr_cushion_standard_78cm',
      selectionMode: 'single',
      resetSelection: false,
      group: 'back_cushion',
      camera: backCushionsCameraSetup,
      labelMode: 'back_cushion',
    },
    'Cushion 85 cm height with roll': {
      zoomMax: 1,
      subTabsName: [],
      text: 'Cushion 85 cm height with roll',
      iconName: 'configurator/flayr/flayr_cushion_roll_78cm',
      selectionMode: 'single',
      resetSelection: false,
      group: 'back_cushion',
      camera: backCushionsCameraSetup,
      labelMode: 'back_cushion',
    },
    colorsWhole: {
      zoomMax: 1.8,
      subTabsName: [
        'colorsWhole',
        'colorsBaseBackrest',
        'colorsCushionArmrest',
      ],
      iconName: 'configurator/flayr/flayr_all_sofa_selected',
      selectionMode: 'all',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_whole',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: 'Whole Couch',
    },
    colors: {
      zoomMax: 1.8,
      subTabsName: [
        'colors',
        'colorsWhole',
        'colorsBaseBackrest',
        'colorsCushionArmrest',
      ],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colors',
      URLTabName: 'colors',
      text: '',
    },
    colorsMobile: {
      zoomMax: 1.8,
      subTabsName: [
        'colorsWhole',
        'colorsBaseBackrest',
        'colorsCushionArmrest',
      ],
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: '',
      camera: baseCameraSetup,
      labelMode: '',
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: '',
    },
    colorsBaseBackrest: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Base & Back',
      iconName: 'configurator/flayr/flayr_basis_selected',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_base_backrest',
    },
    colorsCushionArmrest: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Cushions & Armrests',
      iconName: 'configurator/flayr/flayr_cushion_armrest_selected',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'color',
      camera: baseCameraSetup,
      labelMode: 'color_cushion_armrest',
    },
    modulesMobile: {
      zoomMax: 1.8,
      subTabsName: [
        'height',
        'depth',
        'seat-modules',
        'lounge',
        'corner-modules',
        'open-modules-storage-space',
      ],
      defaultSubTabName: 'height',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
    elementsMobile: {
      zoomMax: 1.8,
      subTabsName: [
        'armrests',
        'Cushion 60 cm height without roll',
        'Cushion 85 cm height with roll',
      ],
      defaultSubTabName: 'armrests',
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      groupStructure: '',
      camera: baseCameraSetup,
      labelMode: '',
    },
  },
  notifications: {},
  // components, which define the primary colour
  // and weights to define priority (default weight is 1)
  primarySections: {
    armrest: 1,
    backrest: 1,
    back_cushion: 1,
    back_roll_cushion: 1,
    base: 10,
    base_pouf: 1,
    seating_cushion: 10,
    sofa_bed_backrest: 1,
    sofa_bed_base: 1,
  },
  basePoufLengthDiff: 0,
  basePoufWidthDiff: 0,
  poufWidthCM: 60,
  isOttoman: false,
  hadOttomanConfigurator: false,
};

export default config;
